<template>
  <div class="blog-header-container">
    <img class="blog-bkash-logo" src="@/assets/image/bKash-logo.svg" alt="img">
    <v-btn
        class="to-home-btn"
        to="/"
        rounded
        color="#e2136e"
    >
      Home
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BlogHeader",
  data(){
    return {
      dashboardLink : process.env.VUE_APP_OWN_BASE_URL+'/dashboard'
    }
  }
}
</script>

<style lang="scss" scoped>
$mobileW: 550px;
$tabW: 750px;

.to-home-btn {
  color: #ffffff;
  padding: 0 !important;
  @media (max-width: $mobileW) {
    width: 30px;
    height: 10px;
  }
}

.blog-header-container {
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .blog-bkash-logo {
    width: 125.4px;
    height: 57.5px;
    @media (max-width: $mobileW) {
      width: 60px;
      height: 30px;
    }
  }

  .blog-header-text {
    font-size: 30px;
    cursor: pointer;
    color: #e2136e;
    @media (max-width: $mobileW) {
      font-size: 14px;
    }
  }
}

</style>