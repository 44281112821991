<template>
  <div class="container">
    <blog-header v-if="!isLoggedIn"></blog-header>
    <v-card :class="isLoggedIn ? 'campaign-details' : 'campaign-details_no_navbar' ">
      <img class="blog-banner" :src="blogDetails.imagePath" alt="img">

      <div class="blog-info-container" v-html="blogDetails.text">
      </div>
    </v-card>
  </div>
</template>

<script>

import BlogHeader from "@/components/blog/BlogHeader";

export default {
  name: "BlogDetails",
  components: {
    BlogHeader,
  },
  computed: {
    blogDetails() {
      return this.$store.getters.getBlogDetails;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.getBlog(id);
  },
  methods: {
    async getBlog(blogId) {
      this.isLoading = true;
      this.$feedback.showLoading();
      try {
        let {data} = await this.$cppClient.post('/blog/get', {blogId})
        this.isLoading = false;
        this.$feedback.hideLoading();
        let blogDetails = data;

        if (!blogDetails){
          this.$router.push('/*');
        }
        this.$store.commit('setBlogDetails', blogDetails);
      } catch ({response}) {
        // console.log('in catch')
        this.isLoading = false;
        this.$feedback.showFailed(response);
      }
    },
  }
}
</script>

<style lang="scss" scoped>

$mobileW: 1000px;
.container {
  background-color: #e7e7e7;
}
.campaign-details {
  margin-top: 16px;
  @media (max-width: $mobileW) {
    margin-top: 64px;
  }
}

.campaign-details_no_navbar {
  margin-top: 16px;
}

.blog-banner {
  //margin-top: 39px;
  width: 100%;
  //height: 400px;
  @media (max-width: $mobileW) {
    width: 100%;
    //height: 200px;
  }
}

.blog-info-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-left: 95px;
  padding-right: 95px;
  min-height: 35vh;
  margin-bottom: 80px;
  margin-top: 28px;
  @media (max-width: $mobileW) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 90px;
    @media (max-width: $mobileW) {
      flex-direction: column-reverse;
    }
  }

  .blog-info-title {
    font-size: 30px;
    text-align: left;
    color: #707070;
    @media (max-width: $mobileW) {
      margin-top: 20px;
    }
  }

  .blog-info-detail {
    font-size: 14px;
    margin-top: 50px;
    text-align: left;
    color: #95989a;
    padding-bottom: 20px;
    @media (max-width: $mobileW) {
      margin-top: 20px;
    }
  }
}
</style>